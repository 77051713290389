/* eslint-disable camelcase */
import { useMemo } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Card, cardRoundedVariants } from '../../../Card'
import { withErrorBoundary } from '../../../ErrorBoundary'
import { HeadingLabel, headingLabelSizes, headingLabelVariants } from '../../../Heading/components'
import { HtmlContent } from '../../../HtmlContent'
import { Image } from '../../../Image'
import styles from './Card.style'

const IngredientCardStyled = styled.div(props => ({ ...styles(props) }))

const IngredientCard = ({
    item,
    titleTagComponent: TitleTag,
    imageId: propImageId,
    imageWidth,
    imageHeight,
    title: propTitle,
    href,
    hrefAs,
    className,
    ...rest
}) => {
    const { name, image: itemImage } = useMemo(() => item || {}, [item])
    const { id: imageId = propImageId } = itemImage || {}

    const title = propTitle || name

    if (!title && !imageId) {
        return null
    }

    return (
        <Card
            title={title}
            href={href}
            hrefAs={hrefAs}
            className={className}
            rounded={cardRoundedVariants.MEDIUM}
            {...rest}>
            <IngredientCardStyled>
                {imageId && (
                    <div className="cardImage">
                        <Image
                            className="cardPicture"
                            image={imageId}
                            placeholderBgColor={
                                itemImage?.colors?.[itemImage?.colors?.length - 1]?.hex || rest?.placeholderBgColor
                            }
                            alt={title}
                            ratio="4:3"
                            height={imageHeight}
                            width={imageWidth}
                        />
                    </div>
                )}
                {title && (
                    <div className="cardContent">
                        <HeadingLabel
                            className="cardContent_title"
                            TagComponent={TitleTag}
                            variant={headingLabelVariants.PRIMARY}
                            size={headingLabelSizes.XLARGE}>
                            <HtmlContent content={title} wrapperComponent="span" />
                        </HeadingLabel>
                    </div>
                )}
            </IngredientCardStyled>
        </Card>
    )
}

IngredientCard.propTypes = {
    item: PropTypes.oneOfType([() => null, PropTypes.object]),
    titleTagComponent: PropTypes.elementType,
    imageId: PropTypes.string,
    imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    className: PropTypes.string
}

IngredientCard.defaultProps = {
    item: undefined,
    titleTagComponent: 'div',
    imageId: undefined,
    imageWidth: 390,
    imageHeight: 360,
    title: undefined,
    href: undefined,
    hrefAs: undefined,
    className: undefined
}

export default withErrorBoundary(IngredientCard)
