import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => {
    const config = {
        borderRadius: {
            outer: toRem(8),
            inner: toRem(6)
        }
    }

    return {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        padding: toRem(8),
        backgroundColor: theme.colors.background.card.base,
        borderRadius: config.borderRadius.outer,
        [theme.breakpoints.up('md')]: {
            padding: toRem(10)
        },
        '& .cardContent': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
            padding: toRems([40, 30]),
            background: theme.colors.background.card.inner.default,
            borderRadius: config.borderRadius.inner,
            [theme.breakpoints.up('md')]: {
                padding: toRems([60, 50])
            },
            '&_body': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
            },
            '&_side': {
                flexShrink: 0,
                maxWidth: toRem(45),
                [theme.breakpoints.up('md')]: {
                    maxWidth: toRem(60)
                }
            },
            '&_icon': {
                maxWidth: '100%',
                height: 'auto'
            },
            '&_content': {
                fontSize: toRem(16),
                lineHeight: 1.4,
                [theme.breakpoints.up('md')]: {
                    fontSize: toRem(20),
                    lineHeight: 1.6
                },
                '& p': {
                    fontSize: 'inherit',
                    lineHeight: 'inherit'
                }
            },
            '&_footer': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'space-around'
            },
            '& .cardContent_body + .cardContent_footer': {
                marginTop: toRem(30),
                [theme.breakpoints.up('md')]: {
                    marginTop: toRem(40)
                }
            },
            '& .cardContent_side + .cardContent_content': {
                marginLeft: toRem(20),
                [theme.breakpoints.up('md')]: {
                    marginLeft: toRem(28)
                }
            }
        }
    }
}

export default style
