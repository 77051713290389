/* eslint-disable camelcase */
// @TODO: Check how to change mobile card variant

import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import Link from 'next/link'
import PropTypes from 'prop-types'

import { Media } from '@hmn/coolinarika-web-core/controllers/MediaContext'

import { Card, cardRoundedVariants } from '../Card'
import { withErrorBoundary } from '../ErrorBoundary'
import {
    HeadingLabel,
    headingLabelSizes,
    headingLabelVariants,
    HeadingSection,
    headingSectionSizes
} from '../Heading/components'
import { HtmlContent } from '../HtmlContent'
import { CheckMarkIcon, Icon } from '../Icon'
import { Image } from '../Image'
import styles from './FoodMenu.style'
import useFoodMenu from './useFoodMenu'

const FoodMenuCardStyled = styled.div(props => ({ ...styles(props) }))

export const foodMenuCardVariants = Object.freeze({
    '1x2': '1x2'
})

const FoodMenuCard = ({
    item,
    itemId: propId,
    title: propTitle,
    titleTagComponent: TitleTag,
    menus,
    href,
    hrefAs,
    className,
    variant,
    fullHeight,
    extraForceHeight,
    ...rest
}) => {
    const { recipeImages, recipeList, todayTitle } = useFoodMenu(item)
    const uid = useUIDSeed()

    const { title: itemTitle } = item || {}

    const title = propTitle || itemTitle

    const imageRatio = elIndex => {
        // On mobile view always return the same image variation

        switch (elIndex) {
            case 0:
                return '1-1-square'
            case 1:
            case 2:
                return '1-1-square'
            default:
                return '1-1-square'
        }
    }

    const imageDimension = elIndex => {
        switch (elIndex) {
            case 0:
                return {
                    width: 480,
                    height: 480
                }
            case 1:
                return {
                    width: 480,
                    height: 480
                }
            case 2:
                return {
                    width: 480,
                    height: 480
                }
            default:
                return {
                    width: 480,
                    height: 480
                }
        }
    }

    return (
        <Card
            title={title}
            href={href}
            hrefAs={hrefAs}
            className={className}
            fullHeight={fullHeight}
            rounded={cardRoundedVariants.MEDIUM}
            {...rest}>
            <FoodMenuCardStyled variant={variant} extraForceHeight={extraForceHeight}>
                <div className="cardPicture">
                    {!!recipeImages?.length &&
                        recipeImages?.map((image, index) => (
                            <Image
                                classNameProgressive={`Image-${index + 1}`}
                                key={`image-${index + 1}`}
                                fullHeight={fullHeight}
                                image={image?.id}
                                alt={image?.alt}
                                ratio={imageRatio(index)}
                                height={imageDimension(index).height}
                                width={imageDimension(index).width}
                            />
                        ))}
                </div>
                <div className="cardContent">
                    {!!title && (
                        <HeadingLabel
                            isUppercase
                            className="cardContent_label"
                            variant={headingLabelVariants.SECONDARY}
                            size={headingLabelSizes.SMALL}>
                            <HtmlContent content={title} wrapperComponent="span" />
                        </HeadingLabel>
                    )}
                    {!!todayTitle && (
                        <HeadingSection
                            className="cardContent_title cardPicture_title"
                            size={headingSectionSizes.HEADING_SMALLER}>
                            {`Jelovnik za ${todayTitle}`}
                        </HeadingSection>
                    )}
                    {recipeList?.length && (
                        <ul className="cardMenuList">
                            {recipeList.map((recipe, index) => (
                                <li key={uid(recipe?.id || index)}>
                                    <Media greaterThan="sm">
                                        <Icon size={22} icon={CheckMarkIcon} />
                                    </Media>
                                    <Link href={`recept/${recipe?.slug}-${recipe?.id}`}>{recipe?.title}</Link>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </FoodMenuCardStyled>
        </Card>
    )
}

FoodMenuCard.propTypes = {
    item: PropTypes.oneOfType([() => null, PropTypes.object]),
    itemId: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    menus: PropTypes.objectOf(PropTypes.shape({})),
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    className: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(foodMenuCardVariants)]),
    fullHeight: PropTypes.bool,
    extraForceHeight: PropTypes.bool
}

FoodMenuCard.defaultProps = {
    item: undefined,
    itemId: undefined,
    title: undefined,
    titleTagComponent: 'h3',
    menus: undefined,
    href: undefined,
    hrefAs: undefined,
    className: undefined,
    variant: foodMenuCardVariants['1x2'],
    fullHeight: true,
    extraForceHeight: false
}

export default withErrorBoundary(FoodMenuCard)
