// @ui imports
import { toRem } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    ...theme.cardHover,
    '& .cardImage': {
        margin: 0
    },
    '& .cardContent': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: 0,
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        padding: toRem(40)
    },
    '& .cardContent_title': {
        margin: 0,
        padding: `0.385em ${toRem(5)}`,
        fontWeight: theme.typography.fontWeight.black,
        fontVariant: 'normal',
        letterSpacing: 0
    }
})

export default style
