/* eslint-disable camelcase */
import { useMemo } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import excerpt from '@hmn/coolinarika-web-core/helpers/excerpt'
import { md, useBreakpoints } from '@hmn/coolinarika-web-core/hooks'

import { Card, cardRoundedVariants } from '../Card'
import { withErrorBoundary } from '../ErrorBoundary'
import { HeadingSection, headingSectionSizes } from '../Heading/components'
import { Image } from '../Image'
import styles from './Card.style'

const CollageCardStyled = styled.div(props => ({ ...styles(props) }))

const collageCardVariants = Object.freeze({
    '1x2': '1x2',
    '2x1': '2x1',
    '2x2': '2x2'
})

/**
 * Unsluggifies and capitalizes inspiration title.
 *
 * @param {*} title
 * @return {*}
 */
const prettifyTitle = title => {
    const result = title.replace(/-/g, ' ')
    return `${result.charAt(0).toUpperCase()}${result.substr(1).toLowerCase()}`
}

const CollageCard = ({
    item,
    itemId: propId,
    title: propTitle,
    titleTagComponent: TitleTag,
    label,
    lead,
    href,
    hrefAs,
    authorId: propAuthorId,
    className,
    variant,
    fullHeight,
    extraForceHeight,
    ...rest
}) => {
    const [isMobileUp] = useBreakpoints(md)

    const { title: itemTitle, images: comboImagesData } = useMemo(() => item || {}, [item])

    const title = propTitle || itemTitle
    const imagesLength = comboImagesData?.length || 0

    const imageRatio = elIndex => {
        // On mobile view always return the same image variation
        if (!isMobileUp) {
            return '2-3-portrait'
        }

        if (variant === collageCardVariants['1x2']) {
            if (imagesLength === 1) {
                return '2-3-portrait'
            }
            if (imagesLength === 2) {
                return '21-9-cinemascope'
            }
        }

        switch (elIndex) {
            case 0:
                return variant === collageCardVariants['1x2'] ? '21-9-cinemascope' : '1-1-square'
            case 1:
                return variant === collageCardVariants['2x1'] ? '2-3-portrait' : '1-1-square'
            case 2:
                return variant === collageCardVariants['2x1'] ? '2-3-portrait' : '1-1-square'
            default:
                return '1-1-square'
        }
    }

    const imageDimension = elIndex => {
        if (imagesLength === 1) {
            return {
                width: 480,
                height: 206
            }
        }
        if (imagesLength === 2) {
            return {
                width: 480,
                height: 720
            }
        }

        switch (elIndex) {
            case 0:
                return {
                    width: 480,
                    height: 206
                }
            case 1:
                return {
                    width: 480,
                    height: 720
                }
            case 2:
                return {
                    width: 480,
                    height: 720
                }
            default:
                return {
                    width: 480,
                    height: 720
                }
        }
    }

    return (
        <Card
            title={prettifyTitle(title)}
            href={href}
            hrefAs={hrefAs}
            className={className}
            fullHeight={fullHeight}
            rounded={cardRoundedVariants.MEDIUM}
            {...rest}>
            <CollageCardStyled variant={variant} extraForceHeight={extraForceHeight} imagesLength={imagesLength}>
                <div className="cardPicture">
                    {comboImagesData?.map((image, index) => (
                        <Image
                            classNameProgressive={`Image-${index + 1}`}
                            key={`image-${index + 1}`}
                            fullHeight={fullHeight}
                            placeholderBgColor={image?.colors?.[image?.colors?.length - 1]?.hex}
                            image={image?.id}
                            alt="Slika kolaža"
                            ratio={imageRatio(index)}
                            height={imageDimension(index).height}
                            width={imageDimension(index).width}
                        />
                    ))}
                </div>
                <div className="cardContent">
                    {title && (
                        <HeadingSection
                            className="cardContent_title cardPicture_title"
                            size={
                                headingSectionSizes[
                                    variant === collageCardVariants['2x2'] ? 'HEADING' : 'HEADING_SMALLER'
                                ]
                            }>
                            {prettifyTitle(title)}
                        </HeadingSection>
                    )}
                    {lead && <div className="cardLead">{excerpt(lead || item?.lead, 80)}</div>}
                </div>
            </CollageCardStyled>
        </Card>
    )
}

CollageCard.propTypes = {
    item: PropTypes.oneOfType([() => null, PropTypes.object]),
    itemId: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    lead: PropTypes.string,
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    authorId: PropTypes.string,
    className: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(collageCardVariants)]),
    fullHeight: PropTypes.bool,
    extraForceHeight: PropTypes.bool,
    label: PropTypes.string
}

CollageCard.defaultProps = {
    item: undefined,
    itemId: undefined,
    title: undefined,
    titleTagComponent: 'h3',
    lead: undefined,
    href: undefined,
    hrefAs: undefined,
    authorId: undefined,
    className: undefined,
    variant: collageCardVariants['1x2'],
    fullHeight: false,
    extraForceHeight: false,
    label: undefined
}

export { collageCardVariants }

export default withErrorBoundary(CollageCard)
