/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const CollectionsAdd = createSvgIcon(
    <>
        <path
            d="M46.7 0c-6.047 0-9.563 4.043-11.391 7.242 0-.035-.036-.035-.036-.07C33.445 3.972 29.813 0 23.766 0c-7.489 0-13.149 6.082-13.149 14.133 0 8.543 6.855 14.273 17.227 22.957 2.953 2.46 4.195 3.41 5.531 4.57a3.421 3.421 0 001.898.809c.95.105 1.934-.14 2.672-.809 5.766-4.992 9.926-8.507 13.441-11.847C55.852 25.523 60 20.672 60 14.133 60 6.082 54.223 0 46.7 0z"
            fill="#FD003A"
        />
        <path
            d="M60 14.133c0 6.539-4.148 11.39-8.613 15.68-3.516 3.34-7.676 6.855-13.442 11.847-.738.668-1.722.914-2.672.809V7.172c0 .035.036.035.036.07C37.137 4.043 40.653 0 46.699 0 54.223 0 60 6.082 60 14.133z"
            fill="#E50027"
        />
        <path
            d="M33.48 37.653l-5.285-6.012h-.07V17.578a3.526 3.526 0 00-3.516-3.515 3.526 3.526 0 00-3.515 3.515v10.547a3.526 3.526 0 00-3.516-3.516 3.526 3.526 0 00-3.515 3.516v3.516a3.526 3.526 0 00-3.516-3.516 3.526 3.526 0 00-3.516 3.516v3.515a3.526 3.526 0 00-3.515-3.515A3.526 3.526 0 000 35.156v11.18c0 .914.14 1.863.457 2.777l3.164 9.692c.211.703.88 1.195 1.652 1.195h21.094c.703 0 1.371-.422 1.617-1.09l6.375-15.844c.739-1.828.387-3.902-.879-5.413z"
            fill="#FEDBAB"
        />
        <path
            d="M34.36 43.066L27.984 58.91c-.247.668-.915 1.09-1.618 1.09h-8.789V24.61a3.526 3.526 0 013.516 3.515V17.578a3.526 3.526 0 013.515-3.515 3.526 3.526 0 013.516 3.515v14.063h.07l5.285 6.012c1.266 1.511 1.618 3.585.88 5.413z"
            fill="#FEC478"
        />
    </>,
    'CollectionsAdd'
)

export default CollectionsAdd
