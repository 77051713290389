// @ui imports
import { responsive, toRem, toRems } from '../../helpers/theme'

/*
 *
 * '4-3-35mm': '4-3-35mm', // "Ratio 4:3 (35mm film) - Recipe Hero, 2x2 Card, 1x1 Card"
 '1-1-square': '1-1-square', // Ratio 1:1 (Square) - Avatars
 '21-9-cinemascope': '21-9-cinemascope', // Ratio 21:9 (Cinemascope) - Article Hero
 '25-9-custom': '25-9-custom', // Ratio 25:9 (Custom) - 2x1 Card
 '2-3-portrait': '2-3-portrait', // Ratio 2:3 (Portrait) - 1x2 Card
 'original-aspect': 'original-aspect' // Original Aspect Ratio
 * */

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, variant, extraForceHeight }) => ({
    overflow: 'hidden',
    height: '100%',
    backgroundColor: theme.colors.card.collage.background,
    ...theme.cardHover,

    '& .cardPicture': {
        display: 'grid',
        gridGap: toRem(2),
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: 'repeat(2, 1fr)',

        '& .Image-1': {
            gridArea: '1 / 1 / 2 / 2'
        },
        '& .Image-2': {
            gridArea: '1 / 2 / 2 / 3'
        },
        '& .Image-3': {
            gridArea: '2 / 1 / 3 / 3',
            paddingBottom: '75%'
        }
    },
    '& .cardContent': {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        margin: 0,
        padding: toRems([40, 30, 35]),
        [theme.breakpoints.up('md')]: {
            padding: toRems([45, 40, 35])
        }
    },
    '& .cardContent_label': {
        position: 'absolute',
        top: 0,
        transform: 'translateY(-50%)',
        margin: 0,
        padding: `0.385em ${toRem(5)}`,
        fontWeight: theme.cards[variant].label.fontWeight
    },
    '& .cardContent_title': {
        marginTop: toRem(10),
        marginBottom: 0
    },
    '& .cardMenuList': {
        ...responsive([
            { marginTop: toRem(16) },
            { marginTop: toRem(16) },
            { marginTop: toRem(18) },
            { marginTop: toRem(19) },
            { marginTop: toRem(20) },
            { marginTop: toRem(22) }
        ]),
        li: {
            display: 'inline-flex',
            alignItems: 'center',
            lineHeight: 1.6,
            a: {
                position: 'relative',
                display: 'inline-block',
                textDecoration: 'none',
                color: theme.colors.text.default,
                transition: 'color 300ms cubic-bezier(0.6, 0.33, 0.43, 0.93)',

                '&::after': {
                    content: '"•"',
                    display: 'inline-block',
                    textAlign: 'center',
                    width: toRem(25),
                    [theme.breakpoints.up('md')]: {
                        content: 'none'
                    }
                },

                '&:hover': {
                    color: theme.colors.link.primary.text.hover
                }
            },
            '&:last-of-type': {
                a: {
                    '&::after': {
                        content: 'none'
                    }
                }
            },
            ...responsive([
                { fontSize: toRem(16) },
                { fontSize: toRem(16) },
                { fontSize: toRem(17) },
                { fontSize: toRem(17) },
                { fontSize: toRem(18) }
            ]),
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                marginBottom: toRem(15)
            }
        }
    }
})

export default style
