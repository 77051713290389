import { useMemo } from 'react'
import { useQuery } from 'react-query'
import dayjs from 'dayjs'

import { parseIRI } from '@hmn/coolinarika-web-core/helpers'
import { globalSettings } from '@hmn/coolinarika-web-core/settings'

const { reactQuerySettings, apiBaseUrl } = globalSettings

const weeklyMenuMap = new Map([
    [dayjs().day(1).format('DD/MM/YYYY'), { key: 'menuMonday', todayTitle: 'ponedjeljak' }],
    [dayjs().day(2).format('DD/MM/YYYY'), { key: 'menuTuesday', todayTitle: 'utorak' }],
    [dayjs().day(3).format('DD/MM/YYYY'), { key: 'menuWednesday', todayTitle: 'srijedu' }],
    [dayjs().day(4).format('DD/MM/YYYY'), { key: 'menuThursday', todayTitle: 'četvrtak' }],
    [dayjs().day(5).format('DD/MM/YYYY'), { key: 'menuFriday', todayTitle: 'petak' }],
    [dayjs().day(6).format('DD/MM/YYYY'), { key: 'menuSaturday', todayTitle: 'subotu' }],
    [dayjs().day(0).format('DD/MM/YYYY'), { key: 'menuSunday', todayTitle: 'nedjelju' }]
])

const useFoodMenu = item => {
    const now = dayjs().format('DD/MM/YYYY')
    // eslint-disable-next-line camelcase
    const { extended_attributes = {} } = item

    const todayMenuIds = useMemo(() => {
        const iriArray = extended_attributes?.[weeklyMenuMap.get(now)?.key] || []

        // map ids with dedup
        return Object.values(
            iriArray.reduce((acc, menuItem) => {
                const { id } = parseIRI(menuItem)
                return { ...acc, [id]: id }
            }, {})
        )
        // eslint-disable-next-line camelcase
    }, [extended_attributes, now])

    const todayTitle = weeklyMenuMap.get(now)?.todayTitle || ''

    const elasticQuery = useMemo(
        () =>
            [
                {
                    ids: todayMenuIds
                }
            ]
                .map(line => (line ? JSON.stringify(line) : line))
                .join('\r\n'),
        [todayMenuIds]
    )

    const { data: recipeData } = useQuery(
        ['feed', 'menu', 'recipes'],
        async () => {
            const response = await fetch(`${apiBaseUrl}/elastic/proxy/podravkaio-coolinarika-combined/_mget`, {
                method: 'POST',
                body: elasticQuery,
                headers: {
                    'content-type': 'application/x-ndjson'
                }
            })
            const data = await response.json()

            return data
        },
        {
            ...reactQuerySettings,
            refetchOnMount: true,
            enabled: !!apiBaseUrl && !!todayMenuIds.length
        }
    )

    const { recipeList, recipeImages } = useMemo(() => {
        const initialValue = {
            recipeList: [],
            recipeImages: []
        }

        if (!recipeData?.docs || !Array.isArray(recipeData?.docs)) {
            return initialValue
        }

        return recipeData?.docs?.reduce(
            (acc, { _source }, index) => ({
                recipeList: [
                    ...acc.recipeList,
                    {
                        id: _source?.id,
                        slug: _source?.slug,
                        title: _source?.title
                    }
                ],
                recipeImages:
                    index < 3
                        ? [...acc.recipeImages, { id: _source?.image?.id, alt: _source?.title }]
                        : acc.recipeImages
            }),
            { ...initialValue }
        )
    }, [recipeData?.docs])

    return { recipeList, recipeImages, todayTitle }
}

export default useFoodMenu
