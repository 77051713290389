// @ui imports
import { responsive, toRem, toRems } from '../../helpers/theme'

/*
 *
 * '4-3-35mm': '4-3-35mm', // "Ratio 4:3 (35mm film) - Recipe Hero, 2x2 Card, 1x1 Card"
 '1-1-square': '1-1-square', // Ratio 1:1 (Square) - Avatars
 '21-9-cinemascope': '21-9-cinemascope', // Ratio 21:9 (Cinemascope) - Article Hero
 '25-9-custom': '25-9-custom', // Ratio 25:9 (Custom) - 2x1 Card
 '2-3-portrait': '2-3-portrait', // Ratio 2:3 (Portrait) - 1x2 Card
 'original-aspect': 'original-aspect' // Original Aspect Ratio
 * */

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, variant, extraForceHeight, imagesLength }) => {
    const variant2x1 = variant === '2x1'
    const variant2x2 = variant === '2x2'
    const variant1x2 = variant === '1x2'

    return {
        overflow: 'hidden',
        height: '100%',
        ...(!variant2x1 && {
            // [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative'
            // }
        }),
        ...(variant2x1 && {
            [theme.breakpoints.up('md')]: {
                display: 'grid',
                gridTemplateColumns: '60% 40%'
            }
        }),
        backgroundColor: theme.colors.card.collage.background,
        ...theme.cardHover,
        '& .cardPicture': {
            display: 'grid',
            gridGap: toRem(2),
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridTemplateRows: 'repeat(2, 1fr)',
            overflow: 'hidden',
            height: '100%',
            '& .Image-1': {
                overflow: 'hidden',
                gridArea: '1 / 1 / 2 / 3',
                ...(imagesLength === 1 &&
                    variant1x2 && {
                        gridArea: '1 / 1 / 4 / 4'
                    }),
                [theme.breakpoints.up('lg')]: {
                    ...(imagesLength !== 1 && {
                        paddingBottom: '64%'
                    })
                }
            },
            '& .Image-2': {
                overflow: 'hidden',
                gridArea: '2 / 1 / 3 / 2',
                ...(imagesLength === 2 &&
                    variant1x2 && {
                        gridArea: '2 / 1 / 3 / 3'
                    }),
                [theme.breakpoints.up('lg')]: {
                    ...(variant1x2 && {
                        paddingBottom: '100%'
                    }),
                    ...(imagesLength === 2 &&
                        variant1x2 && {
                            paddingBottom: '64%'
                        })
                }
            },
            '& .Image-3': {
                overflow: 'hidden',
                gridArea: '2 / 2 / 2 / 3',
                [theme.breakpoints.up('lg')]: {
                    ...(variant1x2 && {
                        paddingBottom: '100%'
                    })
                }
            },
            ...(variant2x1 && {
                [theme.breakpoints.up('md')]: {
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gridTemplateRows: '1fr',
                    '& .Image-1': {
                        gridArea: '1 / 1 / 2 / 2',
                        ...(extraForceHeight && {
                            paddingBottom: '250%'
                        })
                    },
                    '& .Image-2': {
                        gridArea: '1 / 2 / 2 / 3'
                    },
                    '& .Image-3': {
                        gridArea: '1 / 3 / 2 / 4'
                    }
                }
            }),
            ...(variant2x2 && {
                [theme.breakpoints.up('md')]: {
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gridTemplateRows: 'repeat(2, 1fr)',
                    [theme.breakpoints.up('lg')]: {
                        gridTemplateColumns: '60% 40%'
                    },
                    '& .Image-1': {
                        gridArea: '1 / 1 / 3 / 2'
                    },
                    '& .Image-2': {
                        gridArea: '1 / 2 / 2 / 3',
                        [theme.breakpoints.up('lg')]: {
                            paddingBottom: '72.055%'
                        }
                    },
                    '& .Image-3': {
                        gridArea: '2 / 2 / 3 / 3',
                        [theme.breakpoints.up('lg')]: {
                            paddingBottom: '72.055%'
                        }
                    }
                }
            })
        },
        '& .cardContent': {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
            height: 'auto',
            margin: 0,
            padding: toRems([20, 30, 35]),
            [theme.breakpoints.up('md')]: {
                padding: toRems([25, 40, 35]),
                ...(variant2x1 && {
                    [theme.breakpoints.up('md')]: {
                        padding: toRems([25, 40, 35])
                    }
                }),
                ...(variant2x2 && {
                    [theme.breakpoints.up('md')]: {
                        padding: toRems([25, 40, 35])
                    }
                })
            }
        },
        '& .cardContent_label': {
            [theme.breakpoints.down('sm')]: {
                position: 'absolute',
                top: 0,
                transform: 'translateY(-50%)'
            },
            margin: 0,
            padding: `0.385em ${toRem(5)}`,
            fontWeight: theme.cards[variant].label.fontWeight,
            ...((variant1x2 || variant2x2) && {
                position: 'absolute',
                top: toRem(-15)
            })
        },
        '& .cardContent_title': {
            marginTop: toRem(10),
            marginBottom: 0
        },
        '& .cardLead': {
            ...responsive([
                { marginTop: toRem(16) },
                { marginTop: toRem(16) },
                { marginTop: toRem(18) },
                { marginTop: toRem(19) },
                { marginTop: toRem(20) },
                { marginTop: toRem(22) }
            ]),
            ...responsive([
                { fontSize: toRem(16) },
                { fontSize: toRem(16) },
                { fontSize: toRem(16) },
                { fontSize: toRem(18) },
                { fontSize: toRem(18) }
            ]),
            lineHeight: 1.6
        }
    }
}

export default style
