const iriMatch = /^\/api\/(?<resource>[a-z0-9-]*(?:\/[a-z0-9-]*)?)\/(?:.*\/)?(?<id>.*)$/

/**
 * Parse IRI string and match resource/entity and id
 *
 *
 *
 * @param {string} iri
 * @return {Object} { id, entity }
 */
const parseIRI = iri => {
    if (!iri) {
        throw new Error('iri argument is required')
    }

    const { resource, id } = iri.match(iriMatch)?.groups || {}

    return { id, resource }
}

export default parseIRI
