/* eslint-disable react/prop-types */
/* eslint-disable max-len */
// TODO: Cool-chef href
// revisit when data is ready
// revisit for parallaxing article image

import { memo } from 'react'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import { Media } from '@hmn/coolinarika-web-core/controllers/MediaContext'

import {
    _articleCardVariants,
    ArticleCard,
    articleLabelVariants
} from '@hmn/coolinarika-web-ui/components/Article/components/Card'
import { ParallaxBanner } from '@hmn/coolinarika-web-ui/components/Banner/components/Parallax'
import { Button } from '@hmn/coolinarika-web-ui/components/Button'
import { CollageCard, collageCardVariants } from '@hmn/coolinarika-web-ui/components/Collage'
import { ConditionalWrapper } from '@hmn/coolinarika-web-ui/components/ConditionalWrapper'
import {
    CoolChefCardCurrent,
    coolChefCardCurrentVariants
} from '@hmn/coolinarika-web-ui/components/CoolChef/components/CardCurrent'
import { FoodMenuCard, foodMenuCardVariants } from '@hmn/coolinarika-web-ui/components/FoodMenu'
import { headingLabelVariants } from '@hmn/coolinarika-web-ui/components/Heading/components/Label'
import { ArrowSmallIcon, CollectionsAddIcon, Icon } from '@hmn/coolinarika-web-ui/components/Icon'
import { IngredientCard } from '@hmn/coolinarika-web-ui/components/Ingredient/components'
import {
    InspirationCard,
    inspirationCardVariants
} from '@hmn/coolinarika-web-ui/components/Inspiration/components/Card'
import { _InspirationFeatured } from '@hmn/coolinarika-web-ui/components/Inspiration/components/Featured'
import { ContentCard } from '@hmn/coolinarika-web-ui/components/Misc/components/ContentCard'
import { PhotoCard } from '@hmn/coolinarika-web-ui/components/Photo/components/Card'
import { adSlotBgVariants } from '@hmn/coolinarika-web-ui/components/Promotion/components/AdSlot'
import { PromotionCard, promotionCardSizeVariant } from '@hmn/coolinarika-web-ui/components/Promotion/components/Card'
import { RecipeCard, recipeCardVariants } from '@hmn/coolinarika-web-ui/components/Recipe/components/Card'
import { VideoCard } from '@hmn/coolinarika-web-ui/components/Video/components/Card'

import Column from './Column.component'

const AdSlot = dynamic(() => import('@hmn/coolinarika-web-ui/components/Promotion/components/AdSlot'), { ssr: false })

const gridVariantMap = {
    '1x1': '4x1',
    '1x2': '4x2',
    '2x1': '8x1',
    '2x2': '8x2',
    fullWidth: 'fullWidth'
}

// eslint-disable-next-line react/prop-types
const ItemDebugWrapper = ({ children, type, size, grid }) => (
    <div
        style={{
            position: 'relative',
            outline: '1px dashed red',
            background: 'rgba(255, 0, 0, 0.1)',
            width: '100%',
            height: '100%'
        }}>
        <div
            style={{
                position: 'absolute',
                top: 5,
                left: 5,
                background: 'black',
                color: 'white',
                padding: 5,
                zIndex: 10
            }}>
            [{type}] - [{size}] - [{grid}]
        </div>
        {children}
    </div>
)

// Placeholder until data is ready
const articleItem = {
    author: { id: '1' },
    created_at: '2020-03-31T08:41:46Z',
    extended_attributes: {
        type: 'article',
        popularity: '3.2573026872541577',
        seasonality: '0.7786982394564637',
        editorial: '1',
        date_distance: '6.850787064711311'
    },
    gridVariant: '1x1',
    id: '2030599',
    image: { id: '2888103' },
    lead:
        // eslint-disable-next-line max-len
        '<p>Izdržati kod kuće s djecom, pogotovo onom manjom koja ne razumiju zbog čega ne smiju van, izazov je za svakog roditelja.</p>',
    owned_by_user_profile: { id: '1' },
    published_at: '2020-03-30T00:00:00Z',
    slug: '7-stvari-koje-mozete-raditi-s-klincima-kod-kuce',
    title: '7 stvari koje možete raditi s klincima kod kuće',
    updated_at: '2020-05-13T15:43:17Z'
}

const isDev = process.env.NODE_ENV !== 'production'

const FeedItemController = ({
    item,
    type,
    gridVariant,
    isFeedItem,
    customOverrideLabel,
    onClickEventTracking,
    onViewEventTracking
}) => {
    const getPromotionFeedItem = promotionType => {
        switch (promotionType) {
            case 'article':
                return (
                    <ConditionalWrapper
                        condition={isDev}
                        Wrapper={ItemDebugWrapper}
                        type={`promo:${promotionType}`}
                        size={gridVariant}
                        grid={gridVariantMap[gridVariant]}>
                        <ArticleCard
                            labelVariant={
                                gridVariant !== '2x1' ? articleLabelVariants.PRIMARY : articleLabelVariants.SECONDARY
                            }
                            isFeedArticle={isFeedItem}
                            item={articleItem}
                            href={`/blog/${
                                item?.taxons?.['coolinarika-article-category']?.[0]?.slug || 'coolinarika'
                            }/${item?.slug ? `${item?.slug}-` : ''}${item?.id}`}
                            titleTagComponent="h3"
                            imageWidth={gridVariant === '2x2' ? 1024 : 390}
                            imageHeight={gridVariant === '2x2' ? 960 : 360}
                            variant={gridVariant}
                            fullHeight
                            // isParallaxed={gridVariant === '1x2' || gridVariant === '2x2'}
                        />
                    </ConditionalWrapper>
                )

            case 'banner':
                return (
                    <ConditionalWrapper
                        condition={isDev}
                        Wrapper={ItemDebugWrapper}
                        type={`promo:${item.promotion}`}
                        size={gridVariant}
                        grid={gridVariantMap[gridVariant]}>
                        <ConditionalWrapper
                            condition={!!item?.config?.isParallax}
                            Wrapper={ParallaxBanner}
                            height={gridVariant === '1x1' || gridVariant === '2x1' ? 250 * 1.7 : 600 * 1.7}>
                            <>
                                <Media greaterThan="md">
                                    <AdSlot
                                        variant={adSlotBgVariants[gridVariantMap[gridVariant]]}
                                        sizes={item?.desktop?.sizes}
                                        adUnit={item?.desktop?.adUnit}
                                        slotId={item?.desktop?.slotId}
                                        programmaticId={item?.desktop?.programmaticId}
                                    />
                                </Media>
                                <Media lessThan="lg">
                                    <AdSlot
                                        variant={adSlotBgVariants[gridVariantMap[gridVariant]]}
                                        sizes={item?.mobile?.sizes}
                                        adUnit={item?.mobile?.adUnit}
                                        slotId={item?.mobile?.slotId}
                                        programmaticId={item?.mobile?.programmaticId}
                                    />
                                </Media>
                            </>
                        </ConditionalWrapper>
                    </ConditionalWrapper>
                )

            default:
                return (
                    <ConditionalWrapper
                        condition={isDev}
                        Wrapper={ItemDebugWrapper}
                        type={`promo:${item?.promotion || 'default'}`}
                        size={gridVariant}
                        grid={gridVariantMap[gridVariant]}>
                        <PromotionCard
                            fullHeight
                            variant={promotionCardSizeVariant[gridVariant]}
                            cardType={promotionType}
                        />
                    </ConditionalWrapper>
                )
        }
    }

    return (
        <>
            {
                {
                    promotion: (
                        <Column variant={`grid.12.col.${gridVariantMap[gridVariant]}`}>
                            {getPromotionFeedItem(item?.promotion)}
                        </Column>
                    ),
                    recipe: (
                        <Column variant={`grid.12.col.${gridVariantMap[gridVariant]}`}>
                            <ConditionalWrapper
                                condition={isDev}
                                Wrapper={ItemDebugWrapper}
                                type={type}
                                size={gridVariant}
                                grid={gridVariantMap[gridVariant]}>
                                {item?.video ||
                                item?.extended_attributes?.hrVideoTiktok ||
                                item?.extended_attributes?.hrVideoYoutube ? (
                                    <VideoCard
                                        item={item}
                                        href={`/recept/${item?.slug ? `${item?.slug}-` : ''}${
                                            item?.id || item?.content_id
                                        }`}
                                        titleTagComponent="h3"
                                        imageWidth={gridVariant === '2x2' ? 1024 : 390}
                                        imageHeight={gridVariant === '2x2' ? 960 : 360}
                                        variant={recipeCardVariants[gridVariant]}
                                        fullHeight
                                        usernameOnlyLabel
                                        onClickEventTracking={onClickEventTracking}
                                        onViewEventTracking={onViewEventTracking}
                                    />
                                ) : (
                                    <RecipeCard
                                        item={item}
                                        href={`/recept/${item?.slug ? `${item?.slug}-` : ''}${
                                            item?.id || item?.content_id
                                        }`}
                                        titleTagComponent="h3"
                                        imageWidth={gridVariant === '2x2' ? 1024 : 390}
                                        imageHeight={gridVariant === '2x2' ? 960 : 360}
                                        variant={recipeCardVariants[gridVariant]}
                                        fullHeight
                                        usernameOnlyLabel
                                        onClickEventTracking={onClickEventTracking}
                                        onViewEventTracking={onViewEventTracking}
                                    />
                                )}
                            </ConditionalWrapper>
                        </Column>
                    ),
                    article: (
                        <Column variant={`grid.12.col.${gridVariantMap[gridVariant]}`}>
                            <ConditionalWrapper
                                condition={isDev}
                                Wrapper={ItemDebugWrapper}
                                type={type}
                                size={gridVariant}
                                grid={gridVariantMap[gridVariant]}>
                                <ArticleCard
                                    labelVariant={
                                        customOverrideLabel ||
                                        (gridVariant !== '2x1'
                                            ? articleLabelVariants.PRIMARY
                                            : articleLabelVariants.SECONDARY)
                                    }
                                    item={item}
                                    isFeedArticle
                                    href={`/blog/${
                                        item?.taxons?.['coolinarika-article-category']?.[0]?.slug || 'coolinarika'
                                    }/${item?.slug ? `${item?.slug}-` : ''}${item?.id}`}
                                    titleTagComponent="h3"
                                    imageWidth={gridVariant === '2x2' ? 1024 : 390}
                                    imageHeight={gridVariant === '2x2' ? 960 : 360}
                                    variant={gridVariant}
                                    fullHeight
                                    // isParallaxed={gridVariant === '1x2' || gridVariant === '2x2'}
                                />
                            </ConditionalWrapper>
                        </Column>
                    ),
                    ingredient: (
                        <Column variant={`grid.12.col.${gridVariantMap[gridVariant]}`}>
                            <ConditionalWrapper
                                condition={isDev}
                                Wrapper={ItemDebugWrapper}
                                type={type}
                                size={gridVariant}
                                grid={gridVariantMap[gridVariant]}>
                                <IngredientCard
                                    item={item}
                                    href={`/namirnica/${item?.slug ? `${item?.slug}-` : ''}${item?.id}`}
                                />
                            </ConditionalWrapper>
                        </Column>
                    ),
                    taxon: (
                        <Column variant={`grid.12.col.${gridVariantMap[gridVariant]}`}>
                            <ConditionalWrapper
                                condition={isDev}
                                Wrapper={ItemDebugWrapper}
                                type={type}
                                size={gridVariant}
                                grid={gridVariantMap[gridVariant]}>
                                <InspirationCard
                                    isUppercase
                                    href={`/inspiracija/${item?.slug ? `${item?.slug}-` : ''}${item?.id}`}
                                    title={item?.title}
                                    titleTagComponent="h2"
                                    lead={item?.extended_attributes?.intro}
                                    label={item?.extended_attributes?.theme ? 'Tema tjedna' : 'Inspiracija'}
                                    labelVariant={headingLabelVariants.SECONDARY}
                                    imageId={item?.image?.id}
                                    variant={inspirationCardVariants['1x2']}
                                    fullHeight
                                />
                            </ConditionalWrapper>
                        </Column>
                    ),
                    'cool-chef': (
                        <Column variant={`grid.12.col.${gridVariantMap[gridVariant]}`}>
                            <ConditionalWrapper
                                condition={isDev}
                                Wrapper={ItemDebugWrapper}
                                type={type}
                                size={gridVariant}
                                grid={gridVariantMap[gridVariant]}>
                                <CoolChefCardCurrent
                                    userId={item?.author?.id}
                                    variant={coolChefCardCurrentVariants[gridVariant]}
                                    quote={item?.lead}
                                />
                            </ConditionalWrapper>
                        </Column>
                    ),
                    contentCard: (
                        <Column variant="grid.12.col.4x1">
                            <ConditionalWrapper
                                condition={isDev}
                                Wrapper={ItemDebugWrapper}
                                type={type}
                                size={gridVariant}
                                grid={gridVariantMap[gridVariant]}>
                                <ContentCard
                                    ctaComponent={
                                        <Button
                                            title="Da, prijavi me"
                                            iconRightComponent={<Icon icon={ArrowSmallIcon} />}>
                                            Da, prijavi me
                                        </Button>
                                    }
                                    iconComponent={<Icon icon={CollectionsAddIcon} viewBox="0 0 60 60" size={60} />}>
                                    Želiš li kreirati vlastite kolekcije sadržaja?
                                </ContentCard>
                            </ConditionalWrapper>
                        </Column>
                    ),
                    food: (
                        <Column variant="grid.12.col.4x1">
                            <ConditionalWrapper
                                condition={isDev}
                                Wrapper={ItemDebugWrapper}
                                type={type}
                                size={gridVariant}
                                grid={gridVariantMap[gridVariant]}>
                                <CollageCard
                                    item={item}
                                    itemId={item?.id}
                                    authorId={item?.author?.id}
                                    href={`/jela/${item?.parent?.slug}/${item?.slug}`}
                                    title={item?.title}
                                    variant={collageCardVariants[gridVariantMap[gridVariant]]}
                                />
                            </ConditionalWrapper>
                        </Column>
                    ),
                    image: (
                        <Column variant="grid.12.col.4x1">
                            <PhotoCard
                                href={`/slika/${item?.slug ? `${item?.slug}-` : ''}${item?.id}`}
                                authorId={item?.owned_by_user_profile_id}
                                authorDisplayName={item?.author?.display_name}
                                title={item?.name}
                                titleTagComponent="h3"
                                imageId={item?.id}
                            />
                        </Column>
                    ),
                    menu: (
                        <Column variant={`grid.12.col.${gridVariantMap[gridVariant]}`}>
                            <ConditionalWrapper
                                condition={isDev}
                                Wrapper={ItemDebugWrapper}
                                type={type}
                                size={gridVariant}
                                grid={gridVariantMap[gridVariant]}>
                                <FoodMenuCard item={item} variant={foodMenuCardVariants[gridVariantMap[gridVariant]]} />
                            </ConditionalWrapper>
                        </Column>
                    )
                }[type]
            }
        </>
    )
}

export const ItemWithinFeedPropType = PropTypes.shape({
    author: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    created_at: PropTypes.string,
    description: PropTypes.string,
    extended_attributes: PropTypes.shape({
        type: PropTypes.string,
        intro: PropTypes.string,
        quantity: PropTypes.string,
        seasonality: PropTypes.string,
        promotion: PropTypes.string
    }),
    promotion: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    content_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    lead: PropTypes.string,
    owned_by_user_profile: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    published: PropTypes.bool,
    published_at: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    update_at: PropTypes.string
})

FeedItemController.propTypes = {
    type: PropTypes.string.isRequired,
    item: ItemWithinFeedPropType.isRequired,
    gridVariant: PropTypes.string,
    isFeedItem: PropTypes.bool,
    customOverrideLabel: PropTypes.string,
    onClickEventTracking: PropTypes.func,
    onViewEventTracking: PropTypes.func
}

FeedItemController.defaultProps = {
    isFeedItem: true,
    customOverrideLabel: undefined,
    gridVariant: '1x1',
    onClickEventTracking: undefined,
    onViewEventTracking: undefined
}

export default memo(FeedItemController)
