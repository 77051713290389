import { cloneElement } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '../../../ErrorBoundary'
import styles from './ContentCard.style'

const ContentCardStyled = styled.div(props => ({ ...styles(props) }))

const ContentCard = ({ children, className, iconComponent: Icon, ctaComponent: Cta, ...rest }) => {
    if (!children && !Icon && !Cta) {
        return null
    }

    return (
        <ContentCardStyled className={className} {...rest}>
            <div className="cardContent">
                {(children || Icon) && (
                    <div className="cardContent_body">
                        {Icon && (
                            <div className="cardContent_side">
                                {cloneElement(Icon, {
                                    className: `cardContent_icon ${
                                        Icon?.props?.className ? Icon?.props?.className : ''
                                    }`
                                })}
                            </div>
                        )}
                        {children && <div className="cardContent_content">{children}</div>}
                    </div>
                )}
                {Cta && <div className="cardContent_footer">{Cta}</div>}
            </div>
        </ContentCardStyled>
    )
}

ContentCard.propTypes = {
    className: PropTypes.string,
    iconComponent: PropTypes.node,
    ctaComponent: PropTypes.node
}

ContentCard.defaultProps = {
    className: undefined,
    iconComponent: undefined,
    ctaComponent: undefined
}

export default withErrorBoundary(ContentCard)
