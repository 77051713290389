import { useCallback } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import cardVariationHandler from '@hmn/coolinarika-web-core/helpers/card'
import { useOneElastic } from '@hmn/coolinarika-web-core/hooks'

import { Card, cardRoundedVariants } from '../../../Card'
import { withErrorBoundary } from '../../../ErrorBoundary'
import { HeadingLabel, headingLabelSizes, headingLabelVariants } from '../../../Heading/components'
import { Highlight, highlightSizes } from '../../../Highlight'
import { HtmlContent } from '../../../HtmlContent'
import { ArrowSmallIcon, Icon } from '../../../Icon'
import { Image } from '../../../Image'
import styles, { photoCardVariants } from './Card.style'

const PhotoCardStyled = styled.div(props => ({ ...styles(props) }))

// @TODO: Create responsive font size for headingLabelSizes.LARGE or create new variant that will have responsive fs
const photoLabelSizeVariants = Object.freeze({
    '1x1': headingLabelSizes.SMALL,
    '1x2': headingLabelSizes.SMALL,
    '2x1': headingLabelSizes.SMALL,
    '2x2': headingLabelSizes.LARGER
})

// @TODO: Create responsive font size for highlightSizes.LARGE create new variant that will have responsive fs
const photoHightlightSizeVariants = Object.freeze({
    '1x1': highlightSizes.SMALL,
    '1x2': highlightSizes.NORMAL,
    '2x1': highlightSizes.NORMAL,
    '2x2': highlightSizes.LARGE
})

const PhotoCard = ({
    authorDisplayName,
    authorId,
    imageId,
    imageWidth,
    imageHeight,
    imageRatio: initialRatio,
    label,
    title,
    titleTagComponent: TitleTag,
    href,
    hrefAs,
    className,
    variant,
    fullHeight,
    seeAllTitle,
    seeAllCount,
    onCardClick,
    ...rest
}) => {
    const { data: author } = useOneElastic({
        resource: 'ljudi',
        id: authorId,
        enabled: !!authorId && !authorDisplayName
    })

    const displayName = authorDisplayName || author?.display_name

    const DefaultContent = useCallback(
        () =>
            !!(title || label) && (
                <div className="cardContent">
                    {!!(displayName || label) && (
                        <HeadingLabel
                            className="cardContent_label"
                            variant={headingLabelVariants.TERTIARY}
                            size={photoLabelSizeVariants[variant]}>
                            {displayName ? (
                                <span>{displayName}</span>
                            ) : (
                                <HtmlContent content={label} wrapperComponent="span" />
                            )}
                        </HeadingLabel>
                    )}
                    {!!title && (
                        <Highlight
                            size={photoHightlightSizeVariants[variant]}
                            TagComponent={TitleTag}
                            hasHover
                            className="cardContent_title">
                            {title}
                        </Highlight>
                    )}
                </div>
            ),
        [title, label, displayName]
    )

    const SeeAllContent = useCallback(
        () => (
            <div className="cardContent_seeAll">
                {!!seeAllCount && (
                    <div className="cardContent_circle">
                        <div className="cardContent_circleInner">{seeAllCount}</div>
                    </div>
                )}
                {!!seeAllTitle && (
                    <p className="cardContent_seeAllTitle">
                        {seeAllTitle} <Icon icon={ArrowSmallIcon} size={24} />
                    </p>
                )}
            </div>
        ),
        [seeAllCount, seeAllTitle]
    )

    if (!imageId) {
        return null
    }

    return (
        <Card
            title={title}
            href={href}
            hrefAs={hrefAs}
            className={className}
            fullHeight={fullHeight}
            rounded={cardRoundedVariants.MEDIUM}
            onClick={onCardClick}
            {...rest}>
            <PhotoCardStyled variant={variant} isClickable={!!href || typeof onCardClick === 'function'}>
                <Image
                    className="cardPicture"
                    fullHeight={fullHeight}
                    image={imageId}
                    placeholderBgColor={rest?.placeholderBgColor}
                    alt={title}
                    height={imageHeight}
                    width={imageWidth}
                    ratio={cardVariationHandler(variant)}
                />

                {variant === photoCardVariants.SEEALL ? <SeeAllContent /> : <DefaultContent />}
            </PhotoCardStyled>
        </Card>
    )
}

const photoCardRatios = Object.freeze({
    '4-3-35mm': '4-3-35mm', // "Ratio 4:3 (35mm film) - Recipe Hero, 2x2 Card, 1x1 Card"
    '1-1-square': '1-1-square', // Ratio 1:1 (Square) - Avatars
    '21-9-cinemascope': '21-9-cinemascope', // Ratio 21:9 (Cinemascope) - Article Hero
    '25-9-custom': '25-9-custom', // Ratio 25:9 (Custom) - 2x1 Card
    '2-3-portrait': '2-3-portrait', // Ratio 2:3 (Portrait) - 1x2 Card
    'original-aspect': 'original-aspect' // Original Aspect Ratio
})

PhotoCard.propTypes = {
    authorDisplayName: PropTypes.string,
    authorId: PropTypes.string,
    imageId: PropTypes.string,
    imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    className: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(photoCardVariants)]),
    fullHeight: PropTypes.bool,
    imageRatio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    seeAllTitle: PropTypes.string,
    seeAllCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onCardClick: PropTypes.func
}

PhotoCard.defaultProps = {
    authorDisplayName: undefined,
    authorId: undefined,
    imageId: undefined,
    imageWidth: 390,
    imageHeight: 360,
    label: undefined,
    title: undefined,
    titleTagComponent: 'h3',
    href: undefined,
    hrefAs: undefined,
    className: undefined,
    variant: photoCardVariants['1x1'],
    fullHeight: false,
    imageRatio: undefined,
    seeAllTitle: undefined,
    seeAllCount: undefined,
    onCardClick: undefined
}

export { photoCardVariants, photoCardRatios }

export default withErrorBoundary(PhotoCard)
